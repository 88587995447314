import React, { useEffect, useRef, useState } from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import ChangePasswordComponent from '../../components/adminComponents/adminHeaderSidebarTitleComponents/ChangePasswordComponent'
import { useDispatch, useSelector } from 'react-redux'
import { editPassenger, getPassengersByExternalCompanyId } from '../../actions/userActions'
import Select from "react-select";
import { SERVERADDRESS } from '../../constants'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { addReservation, updateReservation } from '../../actions/reservationAction'
import { getFaresByCompanyId, getPrivatesFaresByCompanyId } from '../../actions/fareActions'
import { notifyFailure } from '../toast'
import SearchedByMap from '../adminComponents/Map/SearchedByMap'
import { getServicesByCompanyId } from '../../actions/serviceActions'
import { getBaseLocation } from '../../actions/locationActions'
import MapComponent from './MapComponent'

const ExternalCompanyBookingCreateComponent = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const reservation = useSelector((state) => state.reservation);
    const { loading } = reservation;
    const service = useSelector((state) => state.service);
    const kpi = useSelector((state) => state.kpi);
    const { facility } = kpi
    const location = useSelector((state) => state.location);
    const fare = useSelector((state) => state.fare);
    const auth = useSelector((state) => state.auth);
    const users = useSelector((state) => state.users);
    const students = useSelector((state) => state.students);
    const availableDrivers = useSelector(
        (state) => state.users.allDriversWithoutPagination
    );
    const sockets = useSelector((state) => state.sockets);
    const { socketInstance } = sockets;
    const [mil, setMil] = useState(0);
    const [dir3, setDir3] = useState([]);

    const [fareDetails, setFareDetails] = useState();

    let [amount, setAmount] = useState(0);
    let [willCall, setWillCall] = useState(false);

    let navigate = useNavigate();

    const [showDetailDropDown, setshowDetailDropDown] = useState(false);
    const openDetailDropDown = () => {
        setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    };

    const [isSpecialNumber, setIsSpecialNumber] = useState(false);
    const handleCheckboxChange = () => {
        setIsSpecialNumber(!isSpecialNumber);
    };
    const [isSpecialNumber2, setIsSpecialNumber2] = useState(false);
    const handleCheckboxChange2 = () => {
        setIsSpecialNumber2(!isSpecialNumber2);
    };

    const [showDropDown, setshowDropDown] = useState(false);

    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown);
    };

    const [showUploadFileModal, setShowUploadFileModal] = useState(false);

    const toggleUploadFileModal = () => {
        setShowUploadFileModal((showDropDown) => !showDropDown);
    };

    const [showPickLoc, setShowPicLoc] = useState(false);
    const openPickLoc = () => {
        setShowPicLoc((showPickLoc) => !showPickLoc);
    };
    const [showDropLoc, setDropLoc] = useState(false);
    const openDropLoc = () => {
        setDropLoc((showDropLoc) => !showDropLoc);
        console.log(showDropLoc);
    };
    const [returnTrip, setReturnTrip] = useState(false);
    const [newArrForRT, setNewArrForRT] = useState([]);
    let abc = [];
    const onClickReturnTrip = () => {
        console.log(stopArr[0], stopArr[1], "newArrForRTnewArrForRT abaa");
        setReturnTrip((returnTrip) => !returnTrip);
        stopArr.forEach((e, i) => {
        abc.push(stopArr[stopArr.length - 1 - i]);
        });
        setReturnStopArr(abc);
        console.log(abc, "abcabcabcabcabcabc");
    };
    const onClickCloseReturnTrip = () => {
        setReturnTrip((returnTrip) => !returnTrip);
        setWillCall(false);
        setValues({
        ...values,
        willCall: false,
        });
    };

    const [resId, setResId] = useState("");
    const [values, setValues] = useState({
        name: "",
        customerId: "",
        type: "",
        bookingDate: "",
        seats: "1",
        willCall: false,
        needAttended: false,
        numberOfWheelchairs: "1",
        amSeats: "0",
        needWheel: false,
        needAssigned: false,
        pickUpAddress: "",
        pickUpDate: "",
        pickUpTime: "",
        dropOffAddress: "",
        dropOffDate: "",
        dropOffTime: "",
        dateForReservation: "",
        driverNotes: "",
        dispatchNotes: "",
        distanceFromBaseLocation: "",
        distanceFromPickToDropOff: "",
        totalMileage: "",
        // loadNumber: '',
        // clientIdExternal: '',
        fareAndDistance: "",
        rideHistory: [],
        fullName: "",
        facility: "",
        numberOfPassenger: 1,
        // luggageCount: 0,
        isAccessible: false,
        mobilityStatus: "",
    });
    const handleChange = (fieldName) => (event) => {
        setValues({
        ...values,
        [fieldName]: event.target.value,
        });
    };
    console.log(values);
    const dispatch = useDispatch();
    const navigateDispatching = () => {
        // navigate("/past-bookings");
        navigate("/scheduled-bookings");
        // socketInstance?.emit("send-reservation-req", {
        //   userId: auth.user.id,
        //   reservationId: resId,
        //   role: "admin",
        // });
    };
    const [reservationId, setReservationId] = useState({ id: "" });
    const [details, setDetails] = useState({
        isCompany: "",
        externalCompanyId: "",
    });
    const reservationData = {
        companyId: auth.user.companyId.id,
        customerId: values.customerId,
        type: values.type,
        serviceName: values.fullName,
        bookingDate: moment().format("YYYY-MM-DD"),
        seats: values.seats,
        willCall: values.willCall,
        needAttended: values.needAttended,
        numberOfWheelchairs: values.numberOfWheelchairs,
        amSeats: values.amSeats,
        needWheel: values.needWheel,
        needAssigned: values.needAssigned,
        pickUpAddress: values.pickUpAddress,
        pickUpDate: values.pickUpDate,
        pickUpTime: values.pickUpTime,
        dropOffAddress: values.dropOffAddress,
        dropOffDate: values.dropOffDate,
        dropOffTime: values.dropOffTime,
        dateForReservation: values.dateForReservation,
        driverNotes: values.driverNotes,
        dispatchNotes: values.dispatchNotes,
        facility: values.facility,
        numberOfPassenger: values.numberOfPassenger,
        // luggageCount: values.luggageCount,
        isAccessible: values.isAccessible,
        mobilityStatus: values.mobilityStatus,
        distanceFromBaseLocation: fareDetails?.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails?.distanceFromPickToDropOff,
        totalMileage: mil,
        totalAmount: amount,
        // totalAmount: fareDetails?.driverAmount,
        rideHistory: values.rideHistory,
        loadNumber: values.loadNumber,
        // specialNumber: values.specialNumber,
        clientIdExternal: values.clientIdExternal,
        isCompany: details.isCompany === true ? true : false,
        fareAndDistance: fareDetails,
        externalCompanyId: details.externalCompanyId
        ? details.externalCompanyId
        : "",
        actions: [
        {
            userId: auth.user.id,
            action: "Create",
            details: `${auth.user.firstName} ${auth.user.lastName} has created the booking.`,
        },
        ...(values.dispatchNotes
            ? [
                {
                userId: auth.user.id,
                action: "DispatchNote",
                details: `${auth.user.firstName} ${auth.user.lastName} has added the dispatching note.`,
                },
            ]
            : []),
        ],
    };

    let newArr = [];
    let newArrReturn = [];
    let distanceFromBaseLocation = 0;
    let distanceFromPickToDropOff = 0;
    let distanceFromBaseLocationReturn = 0;
    let distanceFromPickToDropOffReturn = 0;
    let totalMileage = 0;
    let totalMileageReturn = 0;

    //New logic

    const reservationSuccessCB = (createdReservationId) => {
        const assignDriverData = {
        _id: createdReservationId,
        driverId: values.driverId,
        assigneeId: auth.user.id,
        assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
        dispatcherId: auth.user.id,
        };
        setResId(createdReservationId);
        if (assignDriverData.driverId != "" || !assignDriverData.driverId) {
        dispatch(updateReservation(assignDriverData, navigateDispatching, true));
        socketInstance.emit("new-ride-req-send", {
            _id: assignDriverData._id,
            driverId: assignDriverData.driverId,
        });
        }
        if (returnTrip === true) {
        returnReservationData.returnTripIdentifier =
            "cus:" +
            reservationData.customerId +
            "-pd:" +
            reservationData.pickUpDate +
            "-dd" +
            reservationData.dropOffDate;

        console.log("reservatiion data==>", returnReservationData);
        returnReservationData.connectedReservation = {
            reservationId: createdReservationId,
            type: "Original",
        };
        dispatch(
            addReservation(returnReservationData, returnReservationSuccessCB)
        );
        } else {
        navigateDispatching();
        }
    };

    const returnReservationSuccessCB = (createdReservationId) => {
        const assignDriverData = {
        _id: createdReservationId,
        driverId: values.driverId,
        assigneeId: auth.user.id,
        assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
        dispatcherId: auth.user.id,
        };
        // console.log("first")
        setResId(createdReservationId);
        if (assignDriverData.driverId != "" || !assignDriverData.driverId) {
        dispatch(updateReservation(assignDriverData, navigateDispatching, true));
        socketInstance.emit("new-ride-req-send", {
            _id: assignDriverData._id,
            driverId: assignDriverData.driverId,
        });
        }
    };
    // new logic khatam

    const handleSubmit = async (event) => {
        event.preventDefault();
        beforeSubmit();
        console.log(stopArr, "stopaaa");
        if (returnTrip === true) {
        reservationData.rideHistory = stopArr;
        reservationData.dateForReservation =
            stopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
        reservationData.pickUpAddress = stopArr[0].address;
        reservationData.pickUpDate = stopArr[0].date;
        reservationData.pickUpTime = stopArr[0].time;
        reservationData.dropOffAddress = stopArr[stopArr.length - 1].address;
        reservationData.dropOffDate = stopArr[stopArr.length - 1].date;
        reservationData.dropOffTime = stopArr[stopArr.length - 1].time;
        if (isSpecialNumber) {
            reservationData.specialNumber = reservationData.loadNumber;
            delete reservationData.loadNumber;
        }

        returnReservationData.rideHistory = returnStopArr;
        returnReservationData.dateForReservation =
            returnStopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
        returnReservationData.pickUpAddress = returnStopArr[0].address;
        returnReservationData.pickUpDate = returnStopArr[0].date;
        returnReservationData.pickUpTime = returnStopArr[0].time;
        returnReservationData.dropOffAddress =
            returnStopArr[returnStopArr.length - 1].address;
        returnReservationData.dropOffDate =
            returnStopArr[returnStopArr.length - 1].date;
        returnReservationData.dropOffTime =
            returnStopArr[returnStopArr.length - 1].time;
        if (isSpecialNumber2) {
            returnReservationData.specialNumber = returnReservationData.loadNumber;
            delete returnReservationData.loadNumber;
        }

        const abc = JSON.parse(values.type);
        if (abc.name === "Ambulatory") {
            delete reservationData.numberOfWheelchairs;
            delete reservationData.amSeats;
            delete reservationData.needWheel;
            delete reservationData.needAssigned;
            reservationData.type = abc.id;

            delete returnReservationData.numberOfWheelchairs;
            delete returnReservationData.amSeats;
            delete returnReservationData.needWheel;
            delete returnReservationData.needAssigned;
            returnReservationData.type = abc.id;
        } else if (abc.name === "Wheelchair") {
            delete reservationData.seats;
            reservationData.type = abc.id;

            delete returnReservationData.seats;
            returnReservationData.type = abc.id;
        } else if (abc.name === "Stretcher") {
            delete reservationData.numberOfWheelchairs;
            delete reservationData.amSeats;
            delete reservationData.needWheel;
            delete reservationData.needAssigned;
            delete reservationData.seats;
            reservationData.type = abc.id;

            delete returnReservationData.numberOfWheelchairs;
            delete returnReservationData.amSeats;
            delete returnReservationData.needWheel;
            delete returnReservationData.needAssigned;
            delete returnReservationData.seats;
            returnReservationData.type = abc.id;
        }
        for (let key of Object.keys(reservationData)) {
            if (reservationData[key] === "") {
            delete reservationData[key];
            }
        }
        for (let key of Object.keys(returnReservationData)) {
            if (returnReservationData[key] === "") {
            delete returnReservationData[key];
            }
        }
        dispatch(addReservation(reservationData, reservationSuccessCB));
        } else {
        if (isSpecialNumber) {
            reservationData.specialNumber = reservationData.loadNumber;
            delete reservationData.loadNumber;
        }
        reservationData.rideHistory = stopArr;
        reservationData.dateForReservation =
            stopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
        reservationData.pickUpAddress = stopArr[0].address;
        reservationData.pickUpDate = stopArr[0].date;
        reservationData.pickUpTime = stopArr[0].time;
        reservationData.dropOffAddress = stopArr[stopArr.length - 1].address;
        reservationData.dropOffDate = stopArr[stopArr.length - 1].date;
        reservationData.dropOffTime = stopArr[stopArr.length - 1].time;
        const abc = JSON.parse(values.type);
        if (abc.name === "Ambulatory") {
            delete reservationData.numberOfWheelchairs;
            delete reservationData.amSeats;
            delete reservationData.needWheel;
            delete reservationData.needAssigned;
            reservationData.type = abc.id;
        } else if (abc.name === "Wheelchair") {
            delete reservationData.seats;
            reservationData.type = abc.id;
        } else if (abc.name === "Stretcher") {
            delete reservationData.numberOfWheelchairs;
            delete reservationData.amSeats;
            delete reservationData.needWheel;
            delete reservationData.needAssigned;
            delete reservationData.seats;
            reservationData.type = abc.id;
        }
        for (let key of Object.keys(reservationData)) {
            if (reservationData[key] === "") {
            delete reservationData[key];
            }
        }

        dispatch(addReservation(reservationData, reservationSuccessCB));
        }
        if (reservationData?.facility){
        dispatch(editPassenger({_id: reservationData.customerId,
        facility: reservationData?.facility
        }));
        }
        afterSubmit();
    };

    const [displayClient, setDisplayClient] = useState(false);
    // const searchClient = async (data) => {
    //   dispatch(clientSearchFilter(data));
    //   setDisplayClient(true);
    // };
    const beforeSubmit = () => {
        stopArr.forEach((e) => {
        e.date = e.date.substring(0, 10) + "T" + e.time + ":00.000Z";
        console.log(e, "eeee");
        });
        if (returnTrip === true) {
        returnStopArr.forEach((e) => {
            e.date = e.date.substring(0, 10) + "T" + e.time + ":00.000Z";
            console.log(e, "eeee");
        });
        }
    };
    const afterSubmit = () => {
        stopArr.forEach((e) => {
        e.date = e.date.substring(0, 10);
        });
        if (returnTrip === true) {
        returnStopArr.forEach((e) => {
            e.date = e.date.substring(0, 10);
        });
        }
    };
    const [oldName, setOldName] = useState("");

    // useEffect(() => {
    //   dispatch(
    //     getAllClients({
    //       companyId: auth.user.companyId.id,
    //     })
    //   );
    // }, []);
    useEffect(() => {
        dispatch(getPassengersByExternalCompanyId({ companyId: auth.user.companyId.id, externalCompanyId: auth.user.externalCompanyId }));
        dispatch(getServicesByCompanyId({ companyId: auth.user.companyId.id }));
        dispatch(getBaseLocation({ companyId: auth.user.companyId.id }));
    }, []);

    // useEffect(() => {
    //   let timerOut = setTimeout(() => {
    //     if (oldName !== values.name) {
    //       if (values.name.length >= 2) {
    //         searchClient({
    //           // companyId: "63cb14f53f55723694dbd185",
    //           companyId: "auth.user.companyId.id",
    //           name: values.name,
    //         });
    //       }
    //     }
    //   }, 1000);
    //   return () => clearTimeout(timerOut);
    // }, [values.name]);

    // const searchLocation = async (data) => {
    //   dispatch(getLocationsByUserId(data));
    // };
    // const onClickCustomer = (data) => {
    //   const abc = JSON.parse(data);
    //   setDisplayClient(false);
    //   setOldName(abc.firstName + " " + abc.lastName);
    //   setValues({
    //     ...values,
    //     customerId: abc.id,
    //     name: abc.firstName + " " + abc.lastName,
    //   });
    //   console.log(abc.isCompany === false, abc.isCompany, "abc.isCompany");
    //   if (abc.isCompany === false) {
    //     setDetails({ isCompany: abc.isCompany });
    //   } else {
    //     setDetails({
    //       isCompany: abc.isCompany,
    //       externalCompanyId: abc.externalCompanyId,
    //     });
    //   }
    //   // searchLocation({
    //   //   userId: abc.id
    //   // })
    //   setStopArr([
    //     {
    //       address: "",
    //       date: "",
    //       time: "",
    //     },
    //     {
    //       address: "",
    //       date: "",
    //       time: "",
    //     },
    //   ]);
    // };
    // console.log(details, "dddettails");
    // yahan se new logic

    const isInitialRender = useRef(true);
    useEffect(() => {
        if (isInitialRender.current) {
        isInitialRender.current = false;
        } else {
        handleVeryDirectFare();
        }
    }, [returnTrip]);
    const handleVeryDirectFare = () => {
        // setHardCodedFares({
        //   ...hardCodedFares,
        //   // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
        //   [fieldName]: event.target.value,
        // });
        const dh =
        parseFloat(parseFloat(hardCodedFares.distanceFromBaseLocation)) >
        parseFloat(hardCodedFares?.deadHeadThreshold)
            ? parseFloat(
                parseFloat(
                parseFloat(hardCodedFares.distanceFromBaseLocation) *
                    parseFloat(hardCodedFares?.baseFarePerMile)
                ).toFixed(2)
            )
            : 0;
        const temp = parseFloat(
        (
            parseFloat(
            parseFloat(
                parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2)
            ) * parseFloat(hardCodedFares.farePerMile)
            ) +
            // parseFloat(hardCodedFares.offHourFee ? hardCodedFares.offHourFee : 0) +
            parseFloat(hardCodedFares.flatBaseFare) +
            (returnTrip ? dh / 2 : dh)
        ).toFixed(2)
        );

        const tempD = !returnTrip
        ? parseFloat(
            (temp - dh / 2) * (hardCodedFares.driverPercentage / 100).toFixed(2)
            )
        : parseFloat(temp * (hardCodedFares.driverPercentage / 100).toFixed(2));
        // console.log(
        //   parseFloat(hardCodedFares?.deadHeadThreshold),
        //   parseFloat(hardCodedFares.distanceFromBaseLocation),
        //   dh,
        //   "temporary"
        // );
        setFareDetails({
        ...fareDetails,
        distanceFromBaseLocation: parseFloat(
            parseFloat(hardCodedFares?.distanceFromBaseLocation)?.toFixed(2)
        ),
        distanceFromPickToDropOff: parseFloat(
            parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
        ),
        precalculatedMiles: parseFloat(
            parseFloat(hardCodedFares?.precalculatedMiles)?.toFixed(2)
        ),
        apiDistanceFromPickToDropOff: hardCodedFares.apiDistanceFromPickToDropOff,
        apiDistanceFromBaseLocation: hardCodedFares.apiDistanceFromBaseLocation,
        usePrecalculatedMiles: hardCodedFares.usePrecalculatedMiles,
        ...(returnTrip === true && {
            avgDistanceFromPickToDropOff: pickUpToDropOffAvg,
            useAvgDistance: true,
        }),
        totalMileage: parseFloat(
            (
            parseFloat(hardCodedFares.distanceFromBaseLocation) +
            parseFloat(hardCodedFares.distanceFromPickToDropOff)
            ).toFixed(2)
        ),
        baseFare: parseFloat(parseFloat(hardCodedFares.flatBaseFare).toFixed(2)),
        baseFareStructure: {
            values: {
            flatBaseFare: hardCodedFares.flatBaseFare,
            },
            valuesFormula: "flatBaseFare",
        },
        deadheadFare: returnTrip ? dh / 2 : dh,
        deadheadFareStructure: {
            values: {
            baseFarePerMile: hardCodedFares.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
            },
            valuesFormula: returnTrip
            ? "(baseFarePerMile * distanceFromBaseLocation)/2"
            : "baseFarePerMile * distanceFromBaseLocation",
        },
        rideFare: parseFloat(
            (
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
            parseFloat(hardCodedFares.farePerMile)
            ).toFixed(2)
        ),
        rideFareStructure: {
            values: {
            distanceFromPickToDropOff: hardCodedFares.distanceFromPickToDropOff,
            farePerMile: hardCodedFares.farePerMile,
            },
            valuesFormula: "distanceFromPickToDropOff * farePerMile",
        },
        offHourFee: parseFloat(hardCodedFares.offHourFee),
        amount: temp,
        amountStructure: {
            values: {
            rideFare: parseFloat(
                parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
                parseFloat(hardCodedFares.farePerMile)
            ).toFixed(2),
            // offHourFee: parseFloat(hardCodedFares.offHourFee),
            baseFare: parseFloat(hardCodedFares.flatBaseFare).toFixed(2),
            deadheadFare: returnTrip ? dh / 2 : dh,
            },
            // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
            valuesFormula: "rideFare + baseFare + deadheadFare",
        },
        driverPercentage: hardCodedFares.driverPercentage,
        driverAmount: parseFloat(tempD.toFixed(2)),
        companyAmount: parseFloat((temp - tempD).toFixed(2)),
        });
        setAmount(temp);
        setMil(hardCodedFares.distanceFromPickToDropOff);
    };
    const handleVeryDirectFareWhenReturn = () => {
        const dh =
        parseFloat(parseFloat(hardCodedFares.distanceFromBaseLocation)) >
        parseFloat(hardCodedFares?.deadHeadThreshold)
            ? parseFloat(
                parseFloat(
                parseFloat(hardCodedFares.distanceFromBaseLocation) *
                    parseFloat(hardCodedFares?.baseFarePerMile)
                ).toFixed(2)
            )
            : 0;
        const temp = parseFloat(
        (
            parseFloat(
            parseFloat(parseFloat(pickUpToDropOffAvg).toFixed(2)) *
                parseFloat(hardCodedFares.farePerMile)
            ) +
            parseFloat(hardCodedFares.flatBaseFare) +
            (returnTrip ? dh / 2 : dh)
        ).toFixed(2)
        );

        const tempD = !returnTrip
        ? parseFloat(
            (temp - dh / 2) * (hardCodedFares.driverPercentage / 100).toFixed(2)
            )
        : parseFloat(temp * (hardCodedFares.driverPercentage / 100).toFixed(2));
        setHardCodedFares({
        ...hardCodedFares,
        distanceFromPickToDropOff: parseFloat(
            parseFloat(pickUpToDropOffAvg)?.toFixed(2)
        ),
        });
        setFareDetails({
        ...fareDetails,
        distanceFromBaseLocation: parseFloat(
            parseFloat(hardCodedFares?.distanceFromBaseLocation)?.toFixed(2)
        ),
        distanceFromPickToDropOff: parseFloat(
            parseFloat(pickUpToDropOffAvg)?.toFixed(2)
        ),
        precalculatedMiles: parseFloat(
            parseFloat(hardCodedFares?.precalculatedMiles)?.toFixed(2)
        ),
        apiDistanceFromPickToDropOff: hardCodedFares.apiDistanceFromPickToDropOff,
        apiDistanceFromBaseLocation: hardCodedFares.apiDistanceFromBaseLocation,
        usePrecalculatedMiles: hardCodedFares.usePrecalculatedMiles,
        ...(returnTrip === true && {
            avgDistanceFromPickToDropOff: pickUpToDropOffAvg,
            useAvgDistance: true,
        }),
        totalMileage: parseFloat(
            (
            parseFloat(hardCodedFares.distanceFromBaseLocation) +
            parseFloat(pickUpToDropOffAvg)
            ).toFixed(2)
        ),
        baseFare: parseFloat(parseFloat(hardCodedFares.flatBaseFare).toFixed(2)),
        baseFareStructure: {
            values: {
            flatBaseFare: hardCodedFares.flatBaseFare,
            },
            valuesFormula: "flatBaseFare",
        },
        deadheadFare: returnTrip ? dh / 2 : dh,
        deadheadFareStructure: {
            values: {
            baseFarePerMile: hardCodedFares.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
            },
            valuesFormula: returnTrip
            ? "(baseFarePerMile * distanceFromBaseLocation)/2"
            : "baseFarePerMile * distanceFromBaseLocation",
        },
        rideFare: parseFloat(
            (
            parseFloat(pickUpToDropOffAvg).toFixed(2) *
            parseFloat(hardCodedFares.farePerMile)
            ).toFixed(2)
        ),
        rideFareStructure: {
            values: {
            distanceFromPickToDropOff: pickUpToDropOffAvg,
            farePerMile: hardCodedFares.farePerMile,
            },
            valuesFormula: "distanceFromPickToDropOff * farePerMile",
        },
        offHourFee: parseFloat(hardCodedFares.offHourFee),
        amount: temp,
        amountStructure: {
            values: {
            rideFare: parseFloat(
                parseFloat(pickUpToDropOffAvg).toFixed(2) *
                parseFloat(hardCodedFares.farePerMile)
            ).toFixed(2),
            baseFare: parseFloat(hardCodedFares.flatBaseFare).toFixed(2),
            deadheadFare: returnTrip ? dh / 2 : dh,
            },
            valuesFormula: "rideFare + baseFare + deadheadFare",
        },
        driverPercentage: hardCodedFares.driverPercentage,
        driverAmount: parseFloat(tempD.toFixed(2)),
        companyAmount: parseFloat((temp - tempD).toFixed(2)),
        });
        setAmount(temp);
        setMil(pickUpToDropOffAvg);
    };
    // yahan takh new logic
    // yahan se new logic
    const [stopArrUseApi, setStopArrUseApi] = useState(false);
    // yahan takh new logic
    const [stopArr, setStopArr] = useState([
        {
        address: "",
        date: "",
        time: "",
        },
        {
        address: "",
        date: "",
        time: "",
        },
    ]);
    const [eta, setEta] = useState();
    // yahan se new logic
    const [useHardCodedFares, setUseHardCodedFares] = useState(true);
    const [hardCodedFares, setHardCodedFares] = useState({
        flatBaseFare: "",
        farePerMile: "",
        deadHeadThreshold: "",
        baseFarePerMile: "",
        totalMileageAllows: "",
        longDistancePerMile: "",
        offHourFee: "",
        noShowFee: "",
        cancelationFee: "",
        additionalCharges: 0,
        deduction: 0,
        precalculatedMiles: 0,
        usePrecalculatedMiles: false,
    });
    const handleHardCodedFares = (fieldName) => (event) => {
        setHardCodedFares({
        ...hardCodedFares,
        // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
        [fieldName]: event.target.value,
        });
    };
    const [useMiles, setUseMiles] = useState(false);
    const handleUsePrecalculatedMilesTrue = () => {
        setHardCodedFares((prevFares) => ({
        ...prevFares,
        usePrecalculatedMiles: true,
        }));
        setUseMiles(true);
    };
    const handleUsePrecalculatedMilesFalse = () => {
        // Update usePrecalculatedMiles to true
        setHardCodedFares((prevFares) => ({
        ...prevFares,
        usePrecalculatedMiles: false,
        }));
        setUseMiles(true);
    };

    useEffect(() => {
        if (useMiles) {
        recalculate();
        setUseMiles(false);
        }
    }, [useMiles]);

    const recalculate = () => {
        calculateFareFrontEnd();
    };
    // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
    const toogleHardCoded = () => {
        if (!useHardCodedFares === true) {
        setUseDirectFare(false);
        setUseFlatDriverRate(false);
        }
        setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
    };
    const [useDirectFare, setUseDirectFare] = useState(false);
    const [directFare, setDirectFare] = useState(0);
    const toogleDirectFare = () => {
        if (!useDirectFare === true) {
        setUseHardCodedFares(false);
        setUseFlatDriverRate(false);
        }
        setUseDirectFare((useDirectFare) => !useDirectFare);
    };
    const handleDirectFare = (event) => {
        setDirectFare(event.target.value);
    };
    const fareDirectlyCalculate = () => {
        // IDHAR KAAM HOGA DRIVER AMOUNT ORIGINAL USE HO RHI HAI FARE SE NAHI
        let driverAmount = parseFloat(
        (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
        );
        let companyAmount = parseFloat(directFare - driverAmount);
        setFareDetails({
        distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
        totalMileage: fareDetails.totalMileage,
        fare: parseFloat(directFare),
        amount: parseFloat(directFare),
        estimatedTime: fareDetails.estimatedTime,
        etaTime: fareDetails.etaTime,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
        baseFare: 0,
        rideFare: 0,
        offHourFee: 0,
        });
        setAmount(directFare);
    };
    console.log(fareDetails, "fareDetails");
    const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
    const [flatDriverRate, setFlatDriverRate] = useState(0);
    const toogleFlatDriverRate = () => {
        if (!useFlatDriverRate === true) {
        setUseHardCodedFares(false);
        setUseDirectFare(false);
        }
        setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
    };
    const handleFlatDriverRate = (event) => {
        setFlatDriverRate(event.target.value);
    };
    const flatDriverRateRecalculate = () => {
        let driverAmount = parseFloat(flatDriverRate);
        let companyAmount = parseFloat(fareDetails.amount - driverAmount);
        // console.log("chala chala");
        setFareDetails({
        distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
        totalMileage: fareDetails.totalMileage,
        fare: fareDetails.fare,
        amount: fareDetails.amount,
        estimatedTime: fareDetails.estimatedTime,
        etaTime: fareDetails.etaTime,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
        baseFare: 0,
        rideFare: 0,
        // offHourFee: 0,
        baseFare: fareDetails.baseFare,
        rideFare: fareDetails.rideFare,
        offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
        });
        // setAmount(directFare);
    };
    console.log(fareDetails, "fareDetailsfareDetails");
    // yahan takh new logic

    const fareData = async () => {
        // console.log("disp===>", auth.user.companyId.id);
        dispatch(getFaresByCompanyId({ companyId: auth.user.companyId.id }));
        dispatch(getPrivatesFaresByCompanyId({ companyId: auth.user.companyId.id }));
    };
    const [deadHeadForR, setDeadHeadForR] = useState(0);
    const [baseToPickUpR, setBaseToPickUpR] = useState(0);
    const [pickUpToDropOffAvg, setPickUpToDropOffAvg] = useState(0);
    const isInitialRender2 = useRef(true);
    useEffect(() => {
        if (isInitialRender2.current && returnTrip) {
        // console.log("chala hoon mein");
        isInitialRender2.current = false;
        } else {
        if (returnTrip) {
            handleVeryDirectFareWhenReturn();
        }
        }
    }, [returnTrip, pickUpToDropOffAvg]);

    const calculateFareFrontEnd = async () => {
        // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
        // console.log("calculateFareFrontEnd")
        const abc = JSON.parse(values.type);
        let newArr = [];
        stopArr.forEach((e) => {
        newArr.push(e?.address?.address);
        });
        let obj = {};
        // yahan se new logic
        let eta = { hour: [], mins: [] };
        // yahan takh new logic
        await directionsService
        .route({
            origin: {
            query: location.baseLocation.address,
            },
            destination: {
            query: newArr[0],
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
            console.log("response", response);
            distanceFromBaseLocation = parseFloat(
            response.routes[0].legs[0].distance.text
                .replace(/,/g, "")
                .split(" mi")[0]
            );
            obj.distanceFromBaseLocation = distanceFromBaseLocation;
        })
        .catch((e) => {
            notifyFailure(e.message);
            console.log("Directions request failed due to ", e.message);
            // window.alert("Directions request failed due to ", e)
        });
        // yahan se new logic
        let oldDate = new Date(
        stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
        );
        // yahan takh new logic
        for (let i = 0; i < newArr.length - 1; i++) {
        await directionsService
            .route({
            origin: {
                query: newArr[i],
            },
            destination: {
                query: newArr[i + 1],
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
            })
            .then((response) => {
            console.log("response2", response);
            // yahan se new logic
            distanceFromPickToDropOff += parseFloat(
                response.routes[0].legs[0].distance.text
                .replace(/,/g, "")
                .split(" mi")[0]
            );
            setDir3([response]);
            // yahan takh new logic
            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
                const timeString = response.routes[0].legs[0].duration.text;
                const hourRegex = /(\d+)\s*hour/;
                const minuteRegex = /(\d+)\s*min/;

                const hourMatch = hourRegex.exec(timeString);
                const minuteMatch = minuteRegex.exec(timeString);

                const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
                const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
                eta.hour.push(hours);
                eta.mins.push(minutes);

                // eta.hour.push(
                //   parseInt(
                //     response.routes[0].legs[0].duration.text.split(" hour")[0]
                //   )
                // );
                // let min =
                //   response.routes[0].legs[0].duration.text.split(" hour")[1];
                // eta.mins.push(parseInt(min.split(" mins")[0]));

                // let aaa =
                //   (parseInt(
                //     response.routes[0].legs[0].duration.text.split(" hour")[0]
                //   ) *
                //     60 +
                //     parseInt(min.split(" mins")[0])) *
                //   60 *
                //   1000;

                //old comment hai let oldDate wala
                // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();

                let aaa = (hours * 60 + minutes) * 60 * 1000;
                console.log(aaa, oldDate, "eta aarha hai");
                oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
                let oldDateTime = oldDate.toString().split(" ")[4];
                let ind = i + 1;
                setStopArr(
                stopArr.map((stop, index) =>
                    index === ind
                    ? {
                        ...stop,
                        date: new Date(oldDate).toISOString().substring(0, 10),
                        time: oldDateTime.substring(0, 5),
                        }
                    : { ...stop }
                )
                );
                console.log(
                aaa,
                new Date(oldDate).toISOString(),
                oldDateTime.substring(0, 5),
                "eta aarha hai"
                );
            } else {
                eta.mins.push(
                parseInt(
                    response.routes[0].legs[0].duration.text.split(" mins")[0]
                )
                );
                // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
                let aaa =
                parseInt(
                    response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;
                oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
                let oldDateTime = oldDate.toString().split(" ")[4];
                let ind = i + 1;
                setStopArr(
                stopArr.map((stop, index) =>
                    index === ind
                    ? {
                        ...stop,
                        date: new Date(oldDate).toISOString().substring(0, 10),
                        time: oldDateTime.substring(0, 5),
                        }
                    : { ...stop }
                )
                );
                console.log(
                aaa,
                oldDate,
                oldDateTime.substring(0, 5),
                "eta aarha hai"
                );
            }
            console.log("etaeta,", eta);
            // yahan takh new logic
            // distanceFromPickToDropOff = distanceFromPickToDropOff + parseFloat(response.routes[0].legs[0].distance.text.replace(/,/g, '').split(" mi")[0])
            // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
            })
            .catch((e) => {
            notifyFailure(e.message);
            console.log("Directions request failed due to ", e);
            // window.alert("Directions request failed due to ", e)
            });
        }
        // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
        distanceFromBaseLocation = parseFloat(distanceFromBaseLocation.toFixed(2));
        obj.apiDistanceFromBaseLocation = distanceFromBaseLocation;
        setBaseToPickUpR(parseFloat(distanceFromBaseLocation.toFixed(2)));
        distanceFromPickToDropOff = parseFloat(
        distanceFromPickToDropOff.toFixed(2)
        );
        obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
        if (hardCodedFares.usePrecalculatedMiles === true) {
        obj.usePrecalculatedMiles = true;
        distanceFromPickToDropOff = parseFloat(
            parseFloat(hardCodedFares.precalculatedMiles).toFixed(2)
        );
        obj.precalculatedMiles = distanceFromPickToDropOff;
        } else {
        obj.usePrecalculatedMiles = false;
        }
        totalMileage = parseFloat(
        (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
        );
        obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
        obj.totalMileage = totalMileage;
        let fares = fare.fare;
        let baseFare;
        let deadheadFare = 0;
        let rideFare;
        let companyFare = [];
        let serviceFare = [];
        let offHourFee = 0;
        // yahan se new logic
        if (
        details.isCompany === false ||
        details.isCompany === "" ||
        !details.isCompany
        ) {
        // yahan takh new logic
        fares = fare.fare[0].details;
        fares.forEach((e) => {
            if (e.serviceId.id === abc.id) {
            serviceFare.push(e);
            }
        });
        } else {
        fares.forEach((e) => {
            // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
            if (e.business.id === details.externalCompanyId) {
            companyFare.push(...e.details);
            }
        });
        companyFare.forEach((e) => {
            if (e.serviceId.id === abc.id) {
            serviceFare.push(e);
            }
        });
        }
        serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
        let _fare;
        for (let i = 0; i < serviceFare.length; i++) {
        if (totalMileage <= serviceFare[i].totalMileageAllows) {
            _fare = serviceFare[i];
            // console.log(_fare)
            break;
        }
        }
        if (!_fare) {
        _fare = serviceFare[serviceFare.length - 1];
        }
        // yahan se new logic
        // if (useHardCodedFares === true) {
        //   _fare = {
        //     flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
        //     farePerMile: parseFloat(hardCodedFares.farePerMile),
        //     deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
        //     baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
        //     totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
        //     longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
        //     offHourFee: parseFloat(hardCodedFares.offHourFee),
        //     noShowFee: parseFloat(hardCodedFares.noShowFee),
        //     cancelationFee: parseFloat(hardCodedFares.cancelationFee),
        //   };
        // }
        // yahan takh new logic
        obj.fare = _fare;
        if (distanceFromBaseLocation <= _fare.deadHeadThreshold) {
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
            values: { flatBaseFare: _fare.flatBaseFare },
            valuesFormula: "flatBaseFare",
        };
        obj.deadheadFare = 0;
        obj.deadheadFareStructure = {
            values: {
            baseFarePerMile: 0,
            distanceFromBaseLocation: distanceFromBaseLocation,
            },
            valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
        };
        } else {
        // yahan se new logic
        if (_fare.deadHeadThreshold) {
            // baseFare =
            //   _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
            // obj.baseFare = baseFare;
            // obj.baseFareStructure = {
            //   values: {
            //     flatBaseFare: _fare.flatBaseFare,
            //     baseFarePerMile: _fare.baseFarePerMile,
            //     distanceFromBaseLocation: distanceFromBaseLocation,
            //   },
            //   valuesFormula:
            //     "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
            // };
            baseFare = _fare.flatBaseFare;
            obj.baseFare = baseFare;
            obj.baseFareStructure = {
            values: {
                flatBaseFare: _fare.flatBaseFare,
            },
            valuesFormula: "flatBaseFare",
            };
            deadheadFare = returnTrip
            ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
            : _fare.baseFarePerMile * distanceFromBaseLocation;
            obj.deadheadFare = returnTrip
            ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
            : _fare.baseFarePerMile * distanceFromBaseLocation;
            obj.deadheadFareStructure = {
            values: {
                baseFarePerMile: _fare.baseFarePerMile,
                distanceFromBaseLocation: distanceFromBaseLocation,
            },
            valuesFormula: returnTrip
                ? "(baseFarePerMile * distanceFromBaseLocation)/2"
                : "baseFarePerMile * distanceFromBaseLocation",
            };

            // using deadHeadForR for return only
            setDeadHeadForR((_fare.baseFarePerMile * distanceFromBaseLocation) / 2);
        } else {
            baseFare = _fare.flatBaseFare;
            obj.baseFare = baseFare;
            obj.baseFareStructure = {
            values: { flatBaseFare: _fare.flatBaseFare },
            valuesFormula: "flatBaseFare",
            };
            obj.deadheadFare = 0;
            obj.deadheadFareStructure = {
            values: {
                baseFarePerMile: 0,
                distanceFromBaseLocation: distanceFromBaseLocation,
            },
            valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
            };
        }
        // yahan takh new logic
        }
        if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
        // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
        rideFare = distanceFromPickToDropOff * _fare.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
            values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare.farePerMile,
            },
            valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
        } else {
        // yahan se new logic
        if (_fare.totalMileageAllows) {
            rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
            obj.rideFare = rideFare;
            obj.rideFareStructure = {
            values: {
                distanceFromPickToDropOff: distanceFromPickToDropOff,
                longDistancePerMile: _fare.longDistancePerMile,
            },
            valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
            };
        } else {
            rideFare = distanceFromPickToDropOff * _fare.farePerMile;
            obj.rideFare = rideFare;
            obj.rideFareStructure = {
            values: {
                distanceFromPickToDropOff: distanceFromPickToDropOff,
                farePerMile: _fare.farePerMile,
            },
            valuesFormula: "distanceFromPickToDropOff * farePerMile",
            };
        }
        // yahan takh
        }

        const company = auth?.user?.companyId;
        // console.log(company)
        for (let key of Object.keys(company.availability)) {
        console.log(
            key,
            key === moment(stopArr[0].date).format("dddd"),
            "checkss"
        );
        if (key === moment(stopArr[0].date).format("dddd")) {
            if (company.availability[key] === false) {
            offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
            obj.offHourFee = offHourFee;
            }
        }
        }
        // console.log(company?.offHours?.from.substring(0,2))
        // console.log(company?.offHours?.from.substring(3,5))

        if (offHourFee === 0) {
        if (
            parseInt(company?.offHours?.from?.substring(0, 2)) <=
            parseInt(stopArr[0].time.substring(0, 2)) &&
            parseInt(stopArr[0].time.substring(0, 2)) <=
            parseInt(company?.offHours?.to?.substring(0, 2))
        ) {
            if (
            parseInt(company?.offHours?.from?.substring(3, 5)) <=
            parseInt(stopArr[0].time.substring(3, 5))
            ) {
            offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
            obj.offHourFee = offHourFee;
            }
        }
        // else {
        //   console.log("normal")
        // }
        }
        if (offHourFee === 0) {
        amount = (rideFare + baseFare + deadheadFare).toFixed(2);
        // yahan se new logic
        if (useHardCodedFares === true) {
            amount =
            parseFloat(amount) +
            parseFloat(hardCodedFares.additionalCharges) -
            parseFloat(hardCodedFares.deduction);
        }
        obj.amount = parseFloat(amount);
        obj.amountStructure = {
            values: {
            rideFare: rideFare,
            baseFare: baseFare,
            deadheadFare: deadheadFare,
            ...(useHardCodedFares === true && {
                additionalCharges: parseFloat(hardCodedFares.additionalCharges),
                deduction: parseFloat(hardCodedFares.deduction),
            }),
            },
            valuesFormula: "rideFare + baseFare + deadheadFare",
        };
        // yahan takh
        } else {
        // amount = (rideFare + baseFare + offHourFee + deadheadFare).toFixed(2);
        amount = (rideFare + baseFare + deadheadFare).toFixed(2);
        // yahan se new logic
        if (useHardCodedFares === true) {
            amount =
            parseFloat(amount) +
            parseFloat(hardCodedFares.additionalCharges) -
            parseFloat(hardCodedFares.deduction);
        }
        obj.amount = parseFloat(amount);
        obj.amountStructure = {
            values: {
            rideFare: rideFare,
            baseFare: baseFare,
            deadheadFare: deadheadFare,
            // offHourFee: offHourFee,
            ...(useHardCodedFares === true && {
                additionalCharges: parseFloat(hardCodedFares.additionalCharges),
                deduction: parseFloat(hardCodedFares.deduction),
            }),
            },
            // valuesFormula: "rideFare + baseFare + offHourFee + deadheadFare",
            valuesFormula: "rideFare + baseFare + deadheadFare",
        };
        // yahan takh
        }
        // yahan se new logic
        let m = 0;
        let hr = 0;
        let tempHr = 0;
        eta.mins.forEach((e) => {
        m += e;
        });
        tempHr = Math.floor(m / 60);
        m = m % 60;
        eta.hour.forEach((e) => {
        hr += e;
        });
        hr += tempHr;
        let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
        obj.estimatedTime = estimatedTime;
        let newDropOffEta = new Date(
        stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
        ).getTime();
        console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
        let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
        newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
        newDropOffEta = newDropOffEta.toString().split(" ")[4];
        let etaTime = newDropOffEta.substring(0, 5);
        obj.etaTime = etaTime;
        // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
        let driverAmount = !returnTrip
        ? parseFloat(
            (
                (amount - deadheadFare / 2) *
                // (auth?.user?.companyId?.driverPercentage / 100)
                (_fare?.driverPercentage / 100)
            ).toFixed(2)
            )
        : parseFloat(
            // (amount * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
            (amount * (_fare?.driverPercentage / 100)).toFixed(2)
            );
        let companyAmount = parseFloat((amount - driverAmount).toFixed(2));
        // obj.driverPercentage = auth?.user?.companyId?.driverPercentage;
        obj.driverPercentage = _fare?.driverPercentage;
        obj.driverAmount = driverAmount;
        obj.companyAmount = companyAmount;
        console.log("objobjobjobj", obj);
        // setEta(etaTime)
        setEta(estimatedTime);
        setHardCodedFares({
        ...hardCodedFares,
        flatBaseFare: _fare.flatBaseFare,
        farePerMile: _fare.farePerMile,
        deadHeadThreshold: _fare.deadHeadThreshold,
        baseFarePerMile: _fare.baseFarePerMile,
        totalMileageAllows: _fare.totalMileageAllows,
        longDistancePerMile: _fare.longDistancePerMile,
        offHourFee: _fare.offHourFee,
        noShowFee: _fare.noShowFee,
        cancelationFee: _fare.cancelationFee,
        distanceFromBaseLocation: obj.distanceFromBaseLocation,
        apiDistanceFromPickToDropOff: obj.apiDistanceFromPickToDropOff,
        apiDistanceFromBaseLocation: obj.apiDistanceFromBaseLocation,
        distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
        precalculatedMiles: obj.distanceFromPickToDropOff,
        usePrecalculatedMiles: obj.usePrecalculatedMiles,
        // driverPercentage: auth?.user?.companyId?.driverPercentage,
        driverPercentage: _fare?.driverPercentage,
        deadheadFare: deadheadFare == 0 ? 0 : deadheadFare,
        baseFarePerMile: deadheadFare == 0 ? 0 : _fare.baseFarePerMile,
        deadHeadThreshold:
            !_fare?.deadHeadThreshold || _fare?.deadHeadThreshold == NaN
            ? 0
            : _fare.deadHeadThreshold,
        // additionalCharges: 0,
        // deduction: 0,
        });
        // yahan takh new logic
        setAmount(obj.amount);
        setMil(obj.distanceFromPickToDropOff);
        setFareDetails(obj);
        // yahan se new logic
        setStopArrUseApi(false);
        // yahan takh new logic
    };
    console.log(fareDetails, "main hoon fare details");

    useEffect(() => {
        // yahan se new logic
        if (stopArrUseApi === true) {
        if (stopArr.length >= 2) {
            let cond = true;
            // stopArr.forEach((e) => {
            //   for (let key of Object.keys(e)) {
            //     if (e[key] === "") {
            //       cond = false;
            //     }
            //   }
            // });
            stopArr.forEach((e) => {
            if (e.address === "") {
                cond = false;
            }
            });
            if (stopArr[0].time === "" || stopArr[0].date === "") {
            cond = false;
            }
            if (cond === true) {
            calculateFareFrontEnd();
            }
            // console.log((stopArr[0].time === '') && (stopArr[0].date === '' ),cond, "condcondcond")
        }
        }
        // yahan takh new logic
        // yahan se new logic
    }, [stopArr, values]);
    // yahan takh new logic
    console.log(stopArr, "stopArr");
    const handleStopChange = (index, field) => (event) => {
        // yahan se new logic
        setStopArrUseApi(true);
        // yahan takh new logic
        setStopArr(
        stopArr.map((stop, i) =>
            i === index ? { ...stop, [field]: event.target.value } : { ...stop }
        )
        );
    };
    const increaseStop = () => {
        // yahan se new logic
        setStopArrUseApi(true);
        // yahan takh new logic
        setStopArr([
        ...stopArr,
        {
            address: "",
            date: "",
            time: "",
        },
        ]);
    };
    const decreaseStop = (index) => {
        // yahan se new logic
        setStopArrUseApi(true);
        // yahan takh new logic
        setStopArr(stopArr.filter((stop, i) => i !== index));
    };
    const alphabets = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "+",
        "/",
        "*",
        "!",
        "@",
        "#",
        "$",
        "%",
        "^",
        "&",
        "_",
        "(",
        ")",
        ",",
        ".",
    ];

    const [values2, setValues2] = useState({
        name: "",
        customerId: "",
        type: "",
        bookingDate: "",
        seats: "1",
        willCall: false,
        needAttended: false,
        numberOfWheelchairs: "1",
        amSeats: "0",
        needWheel: false,
        needAssigned: false,
        pickUpAddress: "",
        pickUpDate: "",
        pickUpTime: "",
        dropOffAddress: "",
        dropOffDate: "",
        dropOffTime: "",
        dateForReservation: "",
        driverNotes: "",
        dispatchNotes: "",
        distanceFromBaseLocation: "",
        distanceFromPickToDropOff: "",
        totalMileage: "",
        loadNumber: "",
        specialNumber: "",
        // clientIdExternal: '',
        fareAndDistance: "",
        rideHistory: [],
        externalCompanyId: "",
        totalAmount: "",
        facility: "",
    });
    const [returnStopArr, setReturnStopArr] = useState([]);
    console.log("values2", values2);
    const returnReservationData = {
        companyId: auth.user.companyId.id,
        customerId: values.customerId,
        type: values.type,
        serviceName: values.fullName,
        bookingDate: moment().format("YYYY-MM-DD"),
        seats: values.seats,
        willCall: values.willCall,
        needAttended: values.needAttended,
        numberOfWheelchairs: values.numberOfWheelchairs,
        amSeats: values.amSeats,
        needWheel: values.needWheel,
        needAssigned: values.needAssigned,
        pickUpAddress: values2.pickUpAddress,
        pickUpDate: values2.pickUpDate,
        pickUpTime: values2.pickUpTime,
        dropOffAddress: values2.dropOffAddress,
        loadNumber: values2.loadNumber,
        // specialNumber: values2.specialNumber,
        dropOffDate: values2.dropOffDate,
        dropOffTime: values2.dropOffTime,
        dateForReservation: values2.dateForReservation,
        driverNotes: values2.driverNotes,
        dispatchNotes: values2.dispatchNotes,
        distanceFromBaseLocation: values2.distanceFromBaseLocation,
        distanceFromPickToDropOff: values2.distanceFromPickToDropOff,
        totalMileage: values2.totalMileage,
        rideHistory: values2.rideHistory,
        totalAmount: values2.totalAmount,
        facility: values.facility,
        // totalAmount: values2.fareAndDistance.driverAmount,
        externalCompanyId: values2.externalCompanyId
        ? details.externalCompanyId
        : "",
        fareAndDistance: values2.fareAndDistance,
        actions: [
        {
            userId: auth.user.id,
            action: "Create",
            details: `${auth.user.firstName} ${auth.user.lastName} has created the booking.`,
        },
        ...(values2.dispatchNotes
            ? [
                {
                userId: auth.user.id,
                action: "DispatchNote",
                details: `${auth.user.firstName} ${auth.user.lastName} has added the dispatching note.`,
                },
            ]
            : []),
        ],
        // loadNumber: values2.loadNumber,
        // clientIdExternal: values2.clientIdExternal,
    };

    console.log(stopArr, "values==>");
    console.log(values, "valueshhhhhh==>");
    const [foundFacility, setFoundFacility] = useState()
    const previousLocation = async (id) => {
        const { data } = await axios.post(
        `${SERVERADDRESS}/v1/reservation/getLastReservationOfCustomerById`,
        { customerId: id }
        );
        console.log(data, "data re data");
        setStopArr([
        {
            address: data?.pickUpAddress ? data?.pickUpAddress : "",
            date: "",
            time: "",
        },
        {
            address: data?.dropOffAddress ? data?.dropOffAddress : "",
            date: "",
            time: "",
        },
        ]);
        if (data?.facility){
        setFoundFacility(data?.facility)
        }
    };
    useEffect(()=>{
        if (foundFacility){
        setValues({
            ...values,
            facility: foundFacility,
        });
        }
    },[foundFacility])

    const [showDropDownVehicleService, setshowDropDownVehicleService] =
        useState(false);

    const openDropDownVehicleService = () => {
        setshowDropDownVehicleService(
        (showDropDownVehicleService) => !showDropDownVehicleService
        );
    };
    const [showDropDownFacility, setshowDropDownFacility] =
        useState(false);

    const openDropDownFacility = () => {
        setshowDropDownFacility(
        (showDropDownFacility) => !showDropDownFacility
        );
    };

    const [serviceNames, setServiceNames] = useState([]);
    const handleChangeType = (fieldName) => (event) => {
        const sType = JSON.parse(event.target.value);
        const sNames = service?.service.find((x) => x.id === sType.id);
        // console.log(sNames, sType.id, "sNamessNames")

        setValues({
        ...values,
        [fieldName]: event.target.value,
        // //working code remove because service name is not required field
        // ['fullName']: sNames?.serviceName[0]?.fullName,
        });
        setServiceNames(sNames.serviceName);
        // yahan se new logic
        if (fieldName === "type") {
        setStopArrUseApi(true);
        }
        // yahan takh new logic
    };
    // useEffect(()=>{
    //   if ((values.type === '') || (values.fullName === '')){
    //     setValues({
    //       ...values,
    //       ['type']: service?.service[0]?.id,
    //       ['fullName']: service?.service[0]?.serviceName[0]?.fullName,
    //     })
    //     setServiceNames(service?.service[0]?.serviceName)
    //   }
    // },[service])

    // const [selectedStudent, setSelectedStudent] = useState({});
    // useEffect(() => {
    //   if (selectedStudent && selectedStudent.studentId) {
    //     setCustomerDetailsArr(
    //       customerDetailsArr.map((x, i) =>
    //         i === selectedStudent.index
    //           ? { ...x, customerId: selectedStudent.studentId }
    //           : x
    //       )
    //     );
    //   }
    // }, [selectedStudent]);
    useEffect(() => {
        if (auth?.user?.companyId) {
        fareData();
        // reservationsScheduleData();
        }
    }, [auth]);
    const reverseArr = () => {
        setStopArr(stopArr.slice().reverse());
        setStopArrUseApi(true);
    };

    // const findSuggestedDriver = async () => {
    //   if (values.type && stopArr[0].address) {
    //     const abc = JSON.parse(values.type);
    //   }
    // };
    const [arr, setArr] = useState([]);
    const suggestedNearbyDrivers = async () => {
        if (
        values.type &&
        stopArr[0].address &&
        stopArr[0].time &&
        stopArr[stopArr.length - 1].time
        ) {
        const abc = JSON.parse(values.type);
        const { data } = await axios.post(
            `${SERVERADDRESS}/v2/reservation/suggestedNearbyDrivers`,
            {
            companyId: auth?.user?.companyId?.id,
            // driverId: auth?.user?.id,
            serviceType: abc.id,
            lng: stopArr[0].address.longitude,
            lat: stopArr[0].address.latitude,
            pickUpTime: stopArr[0].time,
            dropOffTime: stopArr[stopArr.length - 1].time,
            }
        );
        setArr(data);
        }
    };
    // console.log(values, "values ======>");
    useEffect(() => {
        suggestedNearbyDrivers();
    }, [stopArr, values]);

    const [selectedDriver, setSelectedDriver] = useState(null);

    const handleCheckboxChange3 = (driverId) => {
        setSelectedDriver(driverId);
        setValues({
        ...values,
        driverId: driverId,
        });
    };
    // const [isAgree, setIsAgree] = useState(false)
    // const toggleIsAgree = () => {
    //     setIsAgree((isAgree) => !isAgree);
    // };
    // console.log(isAgree, "isAgree")
    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row p-3">
                        <div className="row px-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-lg-7"> 
                                        <div className="form-group">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Customer</label>
                                            <sup className="text-danger">*</sup>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                value={students?.list
                                                ?.map((x) => ({
                                                    label: `${x.firstName} ${x.lastName}`,
                                                    value: x.id,
                                                }))
                                                .find(
                                                    (customer) =>
                                                    customer.value === values?.customerId
                                                )}
                                                isDisabled={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={true}
                                                required={true}
                                                name="pickUp2"
                                                id="pickUp2"
                                                onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    customerId: e.value,
                                                    name: e.label,
                                                    isCompany: e?.isCompany && e?.isCompany,
                                                    externalCompanyId: e?.externalCompanyId
                                                    ? e.externalCompanyId
                                                    : "",
                                                });
                                                if (e.isCompany === false) {
                                                    setDetails({ isCompany: e.isCompany });
                                                }
                                                else if (e.isCompany === true) {
                                                    setDetails({
                                                    isCompany: e.isCompany,
                                                    externalCompanyId: e.externalCompanyId,
                                                    });
                                                } else {
                                                    setDetails({ isCompany: "" });
                                                }
                                                previousLocation(e.value);
                                                }}
                                                options={students?.list?.map((x) => ({
                                                label: `${x.firstName} ${x.lastName} `,
                                                value: x.id,
                                                isCompany: x?.isCompany && x?.isCompany,
                                                externalCompanyId: x?.externalCompanyId
                                                    ? x.externalCompanyId
                                                    : "",
                                                }))}
                                            />
                                        </div> 
                                    </div>
                                    <div className="col-lg-5"> 
                                        <div className="form-group">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Type</label>
                                            <sup className="text-danger">*</sup>
                                            <select
                                                id="type2"
                                                name="type2"
                                                className="form-control select-picker"
                                                style={{ height: "39px", fontSize: "14px" }}
                                                value={values.type}
                                                onChange={handleChangeType("type")}
                                                required
                                                >
                                                <option value="" id="opt" key={"st"}>
                                                    {" "}
                                                    Select Type
                                                </option>
                                                {service.service &&
                                                    service.service.length > 0 &&
                                                    service.service.map((current, i) => {
                                                    return (
                                                        <option
                                                        value={JSON.stringify({
                                                            id: current.id,
                                                            name: current.name,
                                                        })}
                                                        key={i}
                                                        >
                                                        {current.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div> 
                                    </div>
                                    <div className="col-lg-7"> 
                                        <div className="form-group">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Pick-Up Date</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="date" className="form-control height-35 f-14 rounded-0" placeholder="" name="after_days" id="after_days" min="0" autoComplete="off"
                                                value={stopArr[0].date}
                                                onChange={handleStopChange(0, "date")}
                                                required
                                            />
                                        </div> 
                                    </div>
                                    <div className="col-lg-5"> 
                                        <div className="form-group ">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Pick-Up Time</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="time" className="form-control height-35 f-14 rounded-0" placeholder="" name="after_days" id="after_days" min="0" autoComplete="off"
                                                value={stopArr[0].time}
                                                onChange={handleStopChange(0, "time")}
                                                required
                                            />
                                        </div> 
                                    </div>
                                    {stopArr.length > 0 &&
                                    stopArr.map((current, i) => {
                                    return (
                                        <>
                                            <div className="col-lg-12"> 
                                                <div className="form-group">
                                                    <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">
                                                        {i === 0
                                                        ? "Pick-Up Address" :
                                                        i === stopArr.length -1 ?
                                                            "Drop-off Address" : "Stop Location" }
                                                    </label>
                                                    <sup className="text-danger">*</sup>
                                                    {/* <input type="text" className="form-control height-35 f-14 rounded-0" placeholder="Your Pick-up Location" value="" name="after_days" id="after_days" min="0" autoComplete="off"/> */}
                                                    <SearchedByMap
                                                        stopArr={stopArr}
                                                        setStopArr={setStopArr}
                                                        field="address"
                                                        index={i}
                                                        setStopArrUseApi={setStopArrUseApi}
                                                        searchAddress={stopArr[i].address.address}
                                                    />
                                                </div> 
                                            </div>
                                            {i !== 0 && i === stopArr.length-1 ? (
                                                <div className="col-lg-3">
                                                    <button type="button" className="bg-white  rounded-0 f-14 p-2 mr-3  mb-0 mb-lg-0 mb-md-0" id="btn1"
                                                        onClick={increaseStop}
                                                    >
                                                    {/* <svg className="svg-inline--fa fa-plus fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>  */}
                                                    <i class="fa fa-plus"></i>
                                                    &nbsp;Add Stop
                                                    </button>
                                                </div>
                                                ) : (
                                                <></>
                                            )}
                                            {i >= 2 && i === stopArr.length-1 && (
                                                <div className="col-lg-4">
                                                    <button type="button" className="bg-white  rounded-0 f-14 p-2 mr-3  mb-0 mb-lg-0 mb-md-0" id="btn1"
                                                    onClick={() => decreaseStop(i)}
                                                    >
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-trash3-fill"
                                                    viewBox="0 0 16 16"
                                                    >
                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                    </svg>
                                                    &nbsp;Delete Stop
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )})}
                                    {/* <div className="col-lg-12"> 
                                        <div className="form-group">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Pick-Up Location</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14 rounded-0" placeholder="Your Pick-up Location" value="" name="after_days" id="after_days" min="0" autoComplete="off"/>
                                        </div> 
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="button" className="bg-white  rounded-0 f-14 p-2 mr-3  mb-0 mb-lg-0 mb-md-0" id="btn1">
                                        <i class="fa fa-plus"></i>
                                        &nbsp;Add Stop
                                        </button>
                                    </div>
                                    <div className="col-lg-12" id="inputField1" style={{display: "block"}}>
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Stop Location</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14 rounded-0" placeholder="Your Location" value="" name="after_days" id="after_days" min="0" autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12"> 
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Drop-off Location</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14 rounded-0" placeholder="Your Pick-up Location" value="" name="after_days" id="after_days" min="0" autoComplete="off"/>
                                        </div> 
                                    </div> */}
                                    
                                </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="numberOfPassengers " className="bokinglabel mb-2">Number of Passengers</label>
                                            <div className="input-group mb-3 ">
                                            <span className="input-group-text rounded-0">
                                                {/* <svg className="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg> */}
                                                <i class="fa fa-user"></i>
                                                </span>
                                            <span className="input-group-text rounded-0" id="decreaseBtn"><strong>-</strong></span>
                                            <input type="text" className="form-control text-center" name="numberOfPassengers" id="numberOfPassengers" value="1" autoComplete="off"/>
                                            <span className="input-group-text rounded-0" id="increaseBtn"><strong>+</strong></span>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <label htmlFor="luggageCount " className="bokinglabel mb-2">Luggage Count</label>
                                            <div className="input-group mb-3 ">
                                            <span className="input-group-text rounded-0">
                                                <i class="fa fa-briefcase"></i>
                                                </span>
                                            <span className="input-group-text rounded-0" id="lugdecreaseBtn"><strong>-</strong></span>
                                            <input type="text" className="form-control text-center" name="luggageCount" id="luggageCount" value="0" autoComplete="off"/>
                                            <span className="input-group-text rounded-0" id="lugincreaseBtn"><strong>+</strong></span>
                                            </div>
                                        </div> */}
                                    </div>
                            </div>
                            <div className="col-md-5">
                            <MapComponent stopArr={stopArr} dir3={dir3} totalMi={mil} />
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5643220.323424004!2d-98.64259931244116!3d46.32019015438887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d585b9a60780b9b%3A0x2a2c99b10fea20f!2sMinnesota%2C%20USA!5e0!3m2!1sen!2s!4v1727276630093!5m2!1sen!2s" width="100%" height="380" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <button type="button" className="bg-white rounded-0 f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="btn2">
                            {/* <svg className="svg-inline--fa fa-plus fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg> */}
                            <i class="fa fa-plus"></i>
                            &nbsp;Add Child Seat
                            </button>
                        </div>
                        <div className="col-lg-7" id="inputField2" style={{display: "none"}}>
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Child Seat</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14 rounded-0" placeholder="Your Location" value="" name="after_days" id="after_days" min="0" autoComplete="off"/>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="d-flex gap-2">
                            <div>
                                <label className="bokinglabel" htmlFor="flexSwitchCheckDefault">
                                    {/* <svg className="svg-inline--fa fa-wheelchair fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="wheelchair" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z"></path></svg> */}
                            <i class="fa fa-wheelchair"></i>
                                    &nbsp;Accessible</label>
                            </div>
                            <div className="mx-3">
                                <label className="switch">
                                    <input type="checkbox" className="switch-input" id="toggle" autoComplete="off"/>
                                    <span className="switch-label" data-on="" data-off="">
                                    </span>
                                    <span className="switch-handle"></span>
                                </label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="" className="mb-2 f-16"><span className="text-danger">*</span> Mobility Status </label>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault1">
                                        Manual wheelchair user
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault2">
                                        Power wheelchair user
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault3">
                                        Scooter user
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault4">
                                        Ambulatory (able to walk)
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="" className="mb-2 f-16"><span className="text-danger">*</span> Wheelchair / Scooter Width (measured to the outside of the rear wheels) </label>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault1">
                                        less than 30” wide (most standard chairs average 25”)
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault2">
                                        more than 30” wide
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" autoComplete="off"/>
                                    <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexRadioDefault3">
                                        N/A
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-12 mb-2">
                            <p className="f-16"><span className="text-danger">*</span> By submitting this form, you agree to our Terms and Conditions</p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="form-check form-check-inline ">
                                <input type="checkbox" name="select_all_table" className="form-check-input" autoComplete="off" onChange={toggleIsAgree}/>
                                <label className="form-check-label pl-2 f-16 text-dark mx-1" htmlFor="flexCheckChecked">Yes, I agree</label>
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <button type="button" className="btn-primary rounded f-16 p-2 px-5 mr-3" id="save-form" onClick={handleSubmit} 
                                // disabled={!isAgree}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ExternalCompanyBookingCreateComponent